import React from 'react';
import Slider from '../components/Slider';

const HomePage = () => {
  return (
    <>
      <Slider/>      
    </>
  );
}

export default HomePage;